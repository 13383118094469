.order-task-route {
  @include respond(extra-small-mobile) {
    width: 300px;
  }
  @include respond(small-mobile) {
    width: 300px;
  }
  @include respond(large-mobile) {
    width: 350px;
  }
  @include respond(extra-large-mobile) {
    width: 350px;
  }
}
