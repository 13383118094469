.pages-content {
  h2 {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 15px;
  }

  h3 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 12px;
  }

  h4 {
    font-size: 18px;
    line-height: 30px;
  }

  p {
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 15px;
    color: $grey--seven;

  }

  .content a {
    color: $black--two;
  }

  dl, ul, ol {
    li {
      list-style: none;
      font-size: 15px;
      line-height: 24px;
      font-weight: 400;

      &:last-child {
        margin-bottom: 20px;
      }

      & a {
        color: $black--two;
        line-height: 1.0;
        border-bottom: 1px solid transparent;

        &:hover {
          color: $black--two;
          border-bottom: 1px solid $grey--six;
        }
      }
    }

    li::before {
      content: "\2022";
      color: $secondary;
      font-weight: bold;
      display: inline-block;
      width: 1em;
    }

  }

  .small-divider {
    width: 50px;
    border: 1px solid $primary;
  }
}

.not-allowed {
  cursor: not-allowed !important;
}

.rainbow-circle {
  background: linear-gradient(
                  90deg,
                  rgba(255, 0, 0, 1) 0%,
                  rgba(255, 154, 0, 1) 10%,
                  rgba(208, 222, 33, 1) 20%,
                  rgba(79, 220, 74, 1) 30%,
                  rgba(63, 218, 216, 1) 40%,
                  rgba(47, 201, 226, 1) 50%,
                  rgba(28, 127, 238, 1) 60%,
                  rgba(95, 21, 242, 1) 70%,
                  rgba(186, 12, 248, 1) 80%,
                  rgba(251, 7, 217, 1) 90%,
                  rgba(255, 0, 0, 1) 100%
  ) !important;
}
