.offer-section {
  h4 {
    color: $primary;
  }

  p {
    color: $black--two;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
  }

  .offer-item {
    width: 350px;

    @include respond(extra-small-mobile) {
      width: 95%;
    }
    @include respond(small-mobile) {
      width: 95%;
    }
    @include respond(large-mobile) {
      width: 95%;
    }

    hr {
      width: 50px;
      border: 1px solid $primary;
    }
  }

}
