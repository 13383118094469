/*=============================================
=            base            =
=============================================*/

*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;

  background-color: $white;
}

p:last-child {
  margin-bottom: 0;
}

a,
button {
  display: inline-block;

  cursor: pointer;
  transition: $transition--default;
}

*:focus {
  outline: none;
}

a:focus {
  outline: none;
}

input[type="submit"] {
  cursor: pointer;
}

ul {
  margin: 0;
  padding: 0;

  list-style: outside none none;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: transparent !important;
}

input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
  color: transparent !important;
}

/* FF 4-18 */

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  color: transparent !important;
}

/* FF 19+ */

input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  color: transparent !important;
}

/* IE 10+ */

/*=====  End of base  ======*/
