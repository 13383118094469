/*=============================================
=            team            =
=============================================*/


.single-team-member {
  &:hover {
    .member-image {
      img {
        transform: scale(1.1);
      }

      &:after {
        visibility: visible;

        opacity: 1;
      }

      .social-list {
        visibility: visible;

        opacity: 1;
      }
    }
  }

  &--style-square {
    position: relative;

    .member-image {
      max-width: 100%;
      max-height: 100%;

      border-radius: 0;

      img {
        border-radius: 0;

        @include respond(large-mobile) {
          width: 100%;
        }
      }

      &:after {
        border-radius: 0;
      }

      .social-list {
        top: auto;
        bottom: 25px;
        left: 20px;

        transform: none;
      }
    }

    .member-caption {
      position: absolute;
      z-index: 1;
      bottom: 50px;
      left: 0;

      visibility: hidden;

      width: 100%;
      padding: 20px 40px;

      transition: all 0.5s ease;
      text-align: left;

      opacity: 0;
      color: $white;

      .name {
        font-size: 34px;
        font-weight: 600;
        line-height: 36px;

        margin: 17px 0;

        transition: 0.5s;
        transform: translateY(-50%);

        color: $white;

        @include respond(extra-large-mobile) {
          font-size: 18px;
          line-height: 22px;
        }

        @include respond(extra-small-mobile) {
          font-size: 22px;
          line-height: 25px;
        }

        @include respond(large-desktop) {
          font-size: 25px;
          line-height: 28px;
        }

        @include respond(small-desktop) {
          font-size: 18px;
          line-height: 22px;
        }
      }

      .subtext {
        display: inline-block;

        transition: 0.5s;
        transform: translateY(50%);
      }
    }

    &:hover {
      .member-caption {
        visibility: visible;

        opacity: 1;

        .name {
          transform: translateY(0);
        }

        .subtext {
          transform: translateY(0);
        }
      }
    }
  }
}

.member-image {
  position: relative;

  overflow: hidden;

  max-width: 200px;
  max-height: 200px;
  margin: 0 auto;

  border-radius: 100%;

  img {
    transition: 0.6s;

    border-radius: 100%;
  }


  &:after {
    position: absolute;
    top: 0;
    left: 0;

    display: block;
    visibility: hidden;

    width: 100%;
    height: 100%;

    content: "";
    transition: all 0.5s ease;

    opacity: 0;
    border-radius: 100%;
    background: rgba(51, 51, 51, 0.7);
  }
}

.social-inside {
  position: absolute;
  top: 0;

  width: 100%;
  height: 100%;
}

.social-list {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;

  visibility: hidden;

  width: 100%;

  transition: all 0.5s ease;
  transform: translate(-50%, -50%);

  opacity: 0;

  li {
    font-size: 20px;

    display: inline-block;

    margin: 0 15px;

    color: $white;

    a {
      svg {
        font-size: 16px;

        display: inline-block;

        vertical-align: middle;
      }

      &:hover {
        color: $red--four;
      }
    }
  }
}

.member-caption {
  .name {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;

    margin: 10px 0;
    margin-bottom: 8px;
  }

  .subtext {
    font-size: 16px;
    line-height: 16px;

    overflow: hidden;

    height: 28px;
    margin: 0;

    transition: all 0.5s ease;
    transform: translateY(100%);
    letter-spacing: 0.03em;
  }
}

.member-caption-separately {
  .name {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;

    margin: 10px 0;
    margin-bottom: 8px;
  }

  .subtext {
    font-size: 16px;
    line-height: 16px;

    overflow: hidden;

    height: 28px;
    margin: 0;

    transition: all 0.5s ease;
    transform: translateY(100%);
    letter-spacing: 0.03em;
  }
}


/*=====  End of team  ======*/

