.collapse-tr-text {
  & label {
    color: $grey--seven;
  }
}

.overdue-text {
  background-color: #F08080;
  padding: 3px 5px 3px 5px;
  margin-top: 5px;
  color: #FFFFFF;
  cursor: default;
}


.entity-name {
  margin-bottom: 3px;
  @include respond(large-mobile) {
    max-width: 150px;
  }
}

.active-subtab {
  padding: 5px 12px;
  background-color: $grey--three;
}

.subtab {
  padding: 5px 0;
}

.previous-note {
  border-radius: 5px;
  color: $white;
}
