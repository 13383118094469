/*=============================================
=            about            =
=============================================*/

.about-title-container {
  .dark-title {
    font-size: 14px;
    font-weight: 500;

    letter-spacing: 1px;

    color: $black--two;
  }

  .title {
    font-size: 48px;
    line-height: 1.5;

    color: $black--two;
  }

  .title-text {
    line-height: 1.8;
  }
}


/*----------  about video bg area  ----------*/

.about-video-bg {
  position: relative;

  height: 800px;

  background-repeat: no-repeat;
  background-size: cover;

  @include respond(extra-large-mobile) {
    height: 600px;
  }

  @include respond(large-mobile) {
    height: 400px;
  }

  .video-text {
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 24px;

    top: 50%;

    transform: rotate(-90deg) translateY(-50%);
    text-align: center;
    letter-spacing: 1px;

    @include respond(large-mobile) {
      display: none;
    }

    a {
      color: $black--two;

      &:hover {
        opacity: 0.85;
      }
    }
  }


  .video-text-left {
    position: absolute;
    left: -10%;
  }


  .video-text-right {
    position: absolute;
    right: -15%;
  }

  .play-icon {
    button {
      border: none;
      background: none;

      img {
        transition: 0.6s;
        transform: scale(1);
      }

      &:hover {
        img {
          transform: scale(1.2);
        }
      }
    }
  }

  h2 {
    font-size: 40px;
    font-weight: 500;
    font-style: normal;
    line-height: 50px;

    text-align: center;
    letter-spacing: 12px;
  }

  &--style-two {
    height: 700px;

    @include respond(extra-large-mobile) {
      height: 600px;
    }

    @include respond(large-mobile) {
      height: 400px;
    }
  }
}


/*----------  about page text  ----------*/

.about-page-text {
  font-size: 15px;
  line-height: 26px;

  letter-spacing: 0.5px;

  color: $grey;

  .black-link {
    color: $black--two;
  }


}


/*----------  about two page text block   ----------*/

.about-single-block {
  p {
    font-size: 15px;
    line-height: 26px;

    margin-bottom: 40px;

    &.subtitle {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;

      margin-bottom: 20px;

      color: $black--two;
    }
  }

  h1 {
    font-size: 34px;
    font-weight: 400;
    line-height: 48px;

    margin-bottom: 30px;

    color: $black--two;
  }

  a {
    font-size: 14px;
    font-weight: 500;

    letter-spacing: 1px;

    color: $black--two;
    border-bottom: 1px solid $grey--eleven;

    &:hover {
      border-color: $black--two;
    }
  }
}


/* about section */

.about-section-content {
  @include respond(large-mobile) {
    margin-bottom: 30px;
  }

  .subtitle {
    font-size: 24px;
    font-weight: 700;

    @include respond(large-mobile) {
      font-size: 16px;
    }
  }

  .title {
    font-size: 36px;
    font-weight: 700;

    @include respond(large-mobile) {
      font-size: 28px;
    }
  }
}

.video-icon-content {
  &__title {
    font-family: $spectral;
    font-size: 50px;
    font-weight: 400;
    line-height: 1.3;

    margin-bottom: 50px;

    @include respond(small-desktop) {
      font-size: 42px;
    }

    @include respond(extra-large-mobile) {
      font-size: 42px;
    }

    @include respond(large-mobile) {
      font-size: 42px;
    }

    @include respond(extra-small-mobile) {
      font-size: 30px;
    }
  }

  &__icon-content-wrapper {
    .single-icon-content {
      display: flex;

      width: 440px;
      max-width: 100%;
      margin-bottom: 40px;

      &__icon {
        flex-basis: 100px;
      }

      &__content {
        flex-basis: calc(100% - 100px);

        .title {
          font-family: $spectral;
          font-size: 25px;
          font-weight: 400;
          font-style: italic;
          line-height: 1.3;

          margin-bottom: 25px;

          color: $black--two;

          @include respond(extra-small-mobile) {
            font-size: 22px;
          }
        }

        .text {
          font-size: 15px;
          line-height: 1.5;

          color: $grey;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.about-bg-content {
  padding: 140px 190px;

  text-align: center;

  background-color: $grey--twentyFive;

  @include respond(extra-large-mobile) {
    padding: 100px;
  }

  @include respond(large-mobile) {
    padding: 80px;
  }

  @include respond(extra-small-mobile) {
    padding: 50px 30px;
  }

  @include respond(large-desktop) {
    padding: 100px;
  }

  @include respond(small-desktop) {
    padding: 80px 50px;
  }

  &__title {
    font-family: $spectral;
    font-size: 40px;
    line-height: 1.5;

    margin-bottom: 35px;

    color: $black--two;

    @include respond(small-desktop) {
      font-size: 30px;
    }

    @include respond(extra-large-mobile) {
      font-size: 30px;
    }

    @include respond(large-mobile) {
      font-size: 30px;
    }

    @include respond(extra-small-mobile) {
      font-size: 25px;
    }
  }

  &__text {
    font-family: $spectral;
    font-size: 18px;
    line-height: 2;

    color: $grey;
  }

  &__link {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;

    position: relative;

    padding-bottom: 3px;

    transition: 0.3s;

    color: $black--two;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;

      width: 100%;
      height: 1px;

      content: "";
      transition: 0.3s;

      opacity: 0.2;
      background-color: $black--two;
    }


    &:hover {
      color: $black--two;

      &:after {
        opacity: 1;
      }
    }
  }
}

.about-bg-background {
  height: 100%;

  background-repeat: no-repeat;
  background-size: cover;

  @include respond(extra-large-mobile) {
    height: 400px;
  }

  @include respond(large-mobile) {
    height: 400px;
  }

  @include respond(extra-small-mobile) {
    height: 300px;
  }
}

.about-page-text {
  @include respond(large-mobile) {
    margin-top: 30px;
  }
}

.about-widget {
  .widget-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;

    color: $black--two;
  }

  .widget-content {
    font-size: 15px;
    line-height: 26px;

    margin-bottom: 0;

    color: $grey;

    & a {
      color: $black--two;
      line-height: 1.0;
      border-bottom: 1px solid transparent;

      &:hover {
        color: $black--two;
        border-bottom: 1px solid $grey--six;
      }
    }

  }
}


.cosmetics-home-intro {
  position: relative;

  p {
    &.label {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;

      margin-bottom: 10px;

      color: #333;
    }


    &.description {
      line-height: 1.5;

      max-width: 500px;
      margin-bottom: 30px;
    }

    &.shadow-text {
      font-size: 77px;
      font-weight: 700;
      line-height: 84px;

      position: absolute;
      top: 50%;
      right: 0;

      transform: translateY(-50%) rotate(90deg);

      opacity: 0.1;
      color: #333;

      @include respond(small-mobile) {
        display: none;
      }
    }
  }

  h2 {
    &.title {
      font-size: 48px;
      line-height: 72px;

      margin-bottom: 20px;

      color: #333;

      @include respond(extra-large-mobile) {
        font-size: 38px;
        line-height: 65px;
      }

      @include respond(extra-small-mobile) {
        font-size: 32px;
        line-height: 55px;
      }
    }
  }

  a {
    &.lezada-shop-link {
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;

      letter-spacing: 1px;
      text-transform: uppercase;

      color: #333;
      border-bottom: 1px solid #d6d6d6;

      &:hover {
        border-color: #333;
      }
    }
  }
}


/*=====  End of about  ======*/
