// Bootstrap variable overrides
$table-dark-bg: #222222;
$primary: #222222;
$secondary: #4991E3;
//$secondary: #63B4FC;

/* third party styles */
@import "~bootstrap/scss/bootstrap";
@import "~tippy.js/dist/tippy.css";

/* project styles */
@import "abstracts/variables";
@import "abstracts/mixins";

@import "base/animations";
@import "base/base";
@import "base/typography";
@import "base/utilities";
@import "base/reset";
@import "base/shared";

@import "layouts/footer";
@import "layouts/grid";
@import "layouts/header";
@import "layouts/navigation";
@import "layouts/sidebar";

@import "pages/cart-wishlist";
@import "pages/my-account";
@import "pages/checkout";
@import "pages/compare";
@import "pages/coming-soon";
@import "pages/404";

@import "components/button";
@import "components/form";
@import "components/cta";
@import "components/hero-slider";
@import "components/product-tab";
@import "components/product-thumb";
@import "components/product-modal";
@import "components/product-image-gallery";
@import "components/product-description";
@import "components/breadcrumb";
@import "components/empty-area";
@import "components/shop-header";
@import "components/shop-advance-filter";
@import "components/pagination";
@import "components/section-title";
@import "components/icon-box";
@import "components/map";
@import "components/faq";
@import "components/blog";
@import "components/category";
@import "components/product-slider";
@import "components/product-widget";
@import "components/team";
@import "components/newsletter";
@import "components/shop-info";
@import "components/banner";
@import "components/home";
@import "components/about";
@import "components/sections/how-it-works";
@import "components/sections/what-we-offer";
@import "components/sections/become-a-partner";
@import "components/formFields";
@import "components/stepper";
@import "components/services/laundry_service";
@import "components/client_portal";
@import "components/agent_portal";
@import "components/portals";
