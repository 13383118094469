/*=============================================
=            compare            =
=============================================*/


.compare-table {
  & .table {
    & tbody {
      & tr {
        & th {
          &.title-column {
            font-size: 15px;
            font-weight: 500;

            min-width: 292px;
            margin: 0;
            padding: 10px 30px;

            color: $black--two;
            border: none;
            background-color: $grey--twenty;

            @include respond(extra-large-mobile) {
              min-width: 200px;
            }

            @include respond(large-mobile) {
              min-width: 150px;
              padding: 10px 20px;
            }
          }
        }

        &:first-child th {
          padding-top: 53px;
        }

        &:last-child th {
          padding-bottom: 55px;
        }

        & td {
          min-width: 250px;
          padding: 17px 15px;

          text-align: center;

          border-color: $grey--three;

          &.product-image-title {
            padding: 27px 10px 35px;

            @include respond(large-mobile) {
              min-width: 220px;
            }

            .compare-remove {
              button {
                padding: 0;

                border: none;
                background: none;

                svg {
                  font-size: 25px;
                }

                &:hover {
                  color: $red--two;
                }
              }
            }

            & .image {
              display: block;
              clear: both;

              width: 100%;
              margin: 14px 0 14px;

              img {
                width: auto;
                max-width: 100px;
              }
            }

            & .category {
              float: left;
              clear: both;

              letter-spacing: 0.5px;
              text-transform: capitalize;

              color: $black--two;
            }

            & .product-title {
              display: block;
              overflow: hidden;

              > a {
                font-size: 16px;
                font-weight: 500;
                line-height: 1.5;

                display: block;

                width: 100%;
                margin-top: 5px;

                text-transform: capitalize;

                &:hover {
                  color: $black--two;
                }
              }
            }

            .compare-btn {
              margin: 14px 0 0;

              a,
              button {
                display: inline-block;

                padding: 10px 15px;

                color: $white;
                border: 1px solid $black--two;
                background: none;
                background-color: $black--two;

                &:hover,
                &.active {
                  color: $black--two;
                  background-color: transparent;
                }

                &:disabled {
                  cursor: not-allowed;
                }

                &.disabled {
                  opacity: 0.3;

                  &:hover {
                    background-color: $black--two;
                  }
                }
              }
            }
          }

          &.product-desc {
            & div {
              font-size: 13px;
              line-height: 1.88;

              margin: 0;

              color: $grey;

              & a {
                color: $black--two;
                line-height: 1.0;
                border-bottom: 1px solid transparent;

                &:hover {
                  color: $black--two;
                  border-bottom: 1px solid $grey--six;
                }
              }
            }
          }

          &.product-price {
            .main-price {
              font-size: 14px;
              font-weight: 600;
              line-height: 1.3;

              color: $black--two;

              &.discounted {
                font-size: 10px;
                font-weight: 600;
                line-height: 1.3;

                margin-right: 10px;

                text-decoration: line-through;

                color: $grey--twelve;
              }
            }
          }

          &.product-sku,
          &.product-stock,
          &.product-weight {
            font-size: 13px;
            font-weight: 400;
          }

          &.product-dimensions {
            font-size: 13px;
            font-weight: 400;
          }

          &.product-rating {
            svg {
              margin-right: 5px;

              color: $black--two;

              &.yellow {
                color: $yellow--two;
              }
            }
          }
        }
      }
    }
  }
}

/*=====  End of compare  ======*/

