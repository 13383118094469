.how-it-works-section {
  h4 {
    color: $primary;
  }

  p {
    color: $black--two;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
  }

  .home-how-it-works-item {
    width: 130px;

    @include respond(large-mobile) {
      width: 100%;
    }
    @include respond(extra-large-mobile) {
      width: 100%;
    }
    @include respond(small-desktop) {
      width: 100%;
    }

    .item-icon {
      display: inline-block;
      width: 105px;
      height: 105px;
      line-height: 1;
      transition: all .3s;
      fill: $secondary;
      color: $secondary;
      border: 3px solid $secondary;
      border-radius: 50%;
      background-color: transparent;

      img {
        width: 100px;
        height: 100px;
        padding: 20px;
      }
    }

    h4 {
      color: $primary;
      margin-left: -50px;
      margin-right: -50px;

      @include respond(extra-small-mobile) {
        margin-left: 0;
        margin-right: 0;
      }
      @include respond(small-mobile) {
        margin-left: 0;
        margin-right: 0;
      }
      @include respond(large-mobile) {
        margin-left: 0;
        margin-right: 0;
      }
      @include respond(extra-large-mobile) {
        margin-left: -50px;
        margin-right: -50px;
      }
    }

    p {
      color: $black--two;
      font-size: 14px;
      line-height: 1.5;
      margin-left: -100px;
      margin-right: -100px;

      @include respond(extra-small-mobile) {
        margin-left: 0;
        margin-right: 0;
      }
      @include respond(small-mobile) {
        margin-left: 0;
        margin-right: 0;
      }
      @include respond(large-mobile) {
        margin-left: 0;
        margin-right: 0;
      }

      @include respond(extra-large-mobile) {
        margin-left: -50px;
        margin-right: -50px;
      }
    }

    hr {
      width: 100px;
      border: 1px solid $primary;
    }
  }

  .works-divider {
    padding-top: 35px;

    hr {
      width: 250px;
      border-bottom: 4px dotted $primary;
      border-top-width: 0;
      background-color: #FFFFFF;

      @include respond(extra-small-mobile) {
        width: 30px;
      }
      @include respond(small-mobile) {
        width: 30px;
      }
      @include respond(large-mobile) {
        width: 30px;
      }
      @include respond(extra-large-mobile) {
        width: 100px;
      }
      @include respond(small-desktop) {
        width: 150px;
      }
    }
  }

  .works-divider-4-items {
    padding-top: 35px;

    hr {
      background-color: #FFFFFF;
      width: 200px;
      border-bottom: 4px dotted $primary;
      border-top-width: 0;

      @include respond(extra-small-mobile) {
        width: 15px;
      }
      @include respond(small-mobile) {
        width: 15px;
      }
      @include respond(large-mobile) {
        width: 15px;
      }
      @include respond(extra-large-mobile) {
        width: 100px;
      }
      @include respond(small-desktop) {
        width: 150px;
      }
    }
  }

}
