/*=============================================
=            hero slider            =
=============================================*/

.hero-slider-two {
  &__wrapper {
    .swiper-container {
      .ht-swiper-button-nav-prev,
      .ht-swiper-button-nav-next {
        visibility: hidden;

        width: 60px;
        height: 60px;
        padding: 0;

        transition: $transition--default;

        opacity: 0;
        color: $grey--three;
        border: none;
        background: $white;

        @include respond(small-desktop) {
          width: 40px;
          height: 40px;
        }

        @include respond(extra-large-mobile) {
          visibility: visible;

          width: 40px;
          height: 40px;

          opacity: 1;
        }

        @include respond(large-mobile) {
          visibility: visible;

          width: 30px;
          height: 30px;

          opacity: 1;
        }

        &:after {
          font-size: 15px;
        }

        &:hover {
          color: $black--two;
        }

        &:focus {
          outline: none;
        }

        &.swiper-button-next {
          right: 20px;

          @include respond(large-desktop) {
            right: 20px;
          }

          @include respond(small-desktop) {
            right: 20px;
          }

          @include respond(extra-large-mobile) {
            right: 20px;
          }

          @include respond(large-mobile) {
            right: 10px;
          }
        }

        &.swiper-button-prev {
          left: 20px;

          @include respond(large-desktop) {
            left: 20px;
          }

          @include respond(small-desktop) {
            left: 20px;
          }

          @include respond(extra-large-mobile) {
            left: 20px;
          }

          @include respond(large-mobile) {
            left: 10px;
          }
        }
      }
    }

    &:hover {
      .ht-swiper-button-nav-prev,
      .ht-swiper-button-nav-next {
        visibility: visible;

        opacity: 1;
      }
    }
  }

  &__slide {
    position: relative;

    overflow: hidden;

    width: 100%;
    height: 770px !important;

    @include respond(large-desktop) {
      height: 550px !important;
    }

    @include respond(small-desktop) {
      height: 450px !important;
    }

    @include respond(extra-large-mobile) {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      height: 800px !important;
    }

    @include respond(large-mobile) {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      height: 600px !important;
    }

    @include respond(extra-small-mobile) {
      height: 600px !important;
    }
  }

  &__image {
    position: absolute;
    z-index: 9;
    right: 15%;
    bottom: 0;

    transition: all 1s ease 0s;
    transform: scale(0.8);

    opacity: 0;

    @include respond(large-desktop) {
      max-width: 450px;
    }

    @include respond(small-desktop) {
      right: 20%;

      max-width: 400px;
    }

    @include respond(extra-large-mobile) {
      position: relative;
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;

      display: block;

      max-width: 350px;
      margin: 0 auto;
      margin-bottom: 20px;
    }

    @include respond(large-mobile) {
      position: relative;
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;

      display: block;

      max-width: 300px;
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }

  &__content {
    position: absolute;
    z-index: 99;
    bottom: 25%;
    left: 8%;

    @include respond(extra-large-mobile) {
      position: static;

      width: 100%;

      text-align: center;
    }

    @include respond(large-mobile) {
      position: static;

      width: 100%;

      text-align: center;
    }

    & > * {
      transition: all 1s ease 0s;

      opacity: 0;
    }

    & .sub-title {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.25;

      margin-bottom: 30px;

      transform: translateY(30px);
      letter-spacing: 5px;

      color: $black--two;

      @include respond(small-desktop) {
        font-size: 16px;

        margin-bottom: 20px;
      }

      @include respond(large-mobile) {
        font-size: 16px;

        margin-bottom: 20px;
      }

      @include respond(extra-small-mobile) {
        font-size: 14px;
      }
    }

    & .title {
      font-size: 56px;
      font-weight: 400;
      line-height: 1;

      margin-bottom: 30px;

      transform: translateY(30px);

      color: $black--two;

      @include respond(small-desktop) {
        font-size: 36px;
      }

      @include respond(extra-large-mobile) {
        font-size: 36px;
      }

      @include respond(large-mobile) {
        font-size: 36px;
      }

      @include respond(small-mobile) {
        font-size: 36px;
      }

      @include respond(extra-small-mobile) {
        font-size: 30px;
      }
    }

    & .slider-link {
      transform: translateY(30px);
    }
  }

  &__pagination {
    font-size: 56px;
    font-weight: 400;
    line-height: 1;

    position: absolute;
    right: 4%;
    bottom: 15%;

    display: flex;
    align-items: center;

    @include respond(small-desktop) {
      font-size: 44px;

      & .border {
        width: 50px;
      }
    }

    @include respond(extra-large-mobile) {
      display: none;
    }

    @include respond(large-mobile) {
      display: none;
    }

    & > * {
      transition: all 1s ease 0s;

      opacity: 0;
    }

    & .current {
      transform: translateX(40px);

      color: $black--two;
    }

    & .border {
      display: block;

      width: 80px;
      height: 2px;
      margin: 5px 15px 0;

      transform: translateX(40px);

      border: none !important;
      background-color: $black--two;
    }

    & .total {
      transform: translateX(40px);

      color: rgba($black, 0.5);
    }
  }

  .swiper-slide-active {
    .hero-slider-two {
      &__image {
        transition-delay: 0.3s;
        transform: scale(1);

        opacity: 1;
      }

      &__content {
        & > * {
          opacity: 1;
        }

        & .sub-title {
          transition-delay: 1s;
          transform: translateY(0);
        }

        & .title {
          transition-delay: 1.3s;
          transform: translateY(0);
        }

        & .slider-link {
          transition-delay: 1.6s;
          transform: translateY(0);
        }
      }

      &__pagination {
        & > * {
          opacity: 1;
        }

        & .current {
          transition-delay: 1s;
          transform: translateX(0);
        }

        & .border {
          transition-delay: 1.2s;
          transform: translateX(0);
        }

        & .total {
          transition-delay: 1.4s;
          transform: translateX(0);
        }
      }
    }
  }
}

/*=====  End of hero slider  ======*/

