/*=============================================
=            blog            =
=============================================*/


.blog-grid-post {
  &__image {
    a {
      display: block;

      img {
        width: 100%;
      }
    }
  }

  &__content {
    .post-date {
      margin-bottom: 15px;

      a {
        font-size: 14px;
        line-height: 18px;

        display: inline-block;

        text-transform: capitalize;

        color: $grey--six;
        border-bottom: 1px solid transparent;

        &:hover {
          color: $black--two;
          border-bottom: 1px solid $grey--six;
        }
      }

      svg {
        margin-right: 5px;
      }
    }

    h2 {
      margin-bottom: 0;

      a {
        font-size: 24px;
        line-height: 36px;

        margin-bottom: 15px;

        color: $black--two;
      }
    }

    p {
      &.post-excerpt {
        font-size: 15px;
        line-height: 24px;

        margin-bottom: 15px;

        color: $grey--seven;
      }
    }

    a {
      &.blog-readmore-btn {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3;

        position: relative;

        display: inline-block;

        letter-spacing: 1px;
        text-transform: uppercase;

        color: $black--two;

        &:after {
          position: absolute;
          bottom: 0;
          left: 0;

          width: 100%;
          height: 1px;

          content: "";

          opacity: 0.2;
          background-color: $black--two;
        }

        svg {
          font-size: 0;
          font-weight: 400;
          line-height: 0;

          display: inline-block;
          visibility: hidden;

          padding-right: 0;

          transition: 0.6s;

          opacity: 0;
        }


        &:hover {
          color: $red--one;

          svg {
            font-size: 20px;
            line-height: 0;

            visibility: visible;

            opacity: 1;
          }
        }
      }
    }
  }

  &--list {
    display: flex;

    padding-bottom: 60px;

    border-bottom: 1px solid $grey--sixteen;

    @include respond(large-mobile) {
      display: block;
    }

    .blog-grid-post__image {
      flex-basis: 450px;

      @include respond(small-desktop) {
        flex-basis: 320px;
      }

      @include respond(extra-large-mobile) {
        flex-basis: 320px;
      }

      @include respond(large-mobile) {
        margin-bottom: 30px;
      }
    }

    .blog-grid-post__content {
      flex-basis: calc(100% - 450px);

      padding-left: 30px;

      @include respond(small-desktop) {
        flex-basis: calc(100% - 320px);
      }

      @include respond(extra-large-mobile) {
        flex-basis: calc(100% - 320px);
      }

      @include respond(large-mobile) {
        padding-left: 0;
      }

      .post-date {
        line-height: 1;

        margin-bottom: 0;
      }
    }
  }

  &--sticky {
    border-bottom: 1px solid $grey--twenty;


    .post-title {
      font-size: 34px;
      line-height: 1.3;
    }
  }
}

/*=====  End of blog  ======*/
