.top-menu {
  button {
    font-weight: 400;

    display: inline-block;

    letter-spacing: 1px;

    color: $black--four;
    border-radius: 0;
    border: 1px solid $white;
    background-color: $white;

    font-size: 13px;

    padding: 12px 15px;

    &:hover {
      color: $white;
      border: 1px solid $black--four;
      background-color: $black--four;
    }

    &.active {
      color: $white;
      border: 1px solid $black--four;
      background-color: $black--four;
    }
  }
}
