.stepper {
  button {
    cursor: default;

    &.active {
      background-color: $secondary;

      &:hover {
        background-color: $secondary !important;
      }

    }

    &.completed {
      background-color: $black--two;

      &:hover {
        background-color: $black--two !important;
      }

    }

    //&:hover {
    //  background-color: $secondary !important;
    //}
  }
}
