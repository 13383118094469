.home-top-section {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  .home-background-overlay {
    background-color: #3F93F3A3;
    opacity: 0.48;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }

  .home-shape-bottom {
    bottom: -1px;
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;
    transform: rotate(180deg);

    svg {
      width: calc(250% + 1.3px);
      height: 250px;
      display: block;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      // z-index: -1
    }
  }

  .home-shape-fill {
    fill: #FFFFFF;
    transform-origin: center;
    transform: rotateY(0deg);
  }

  .home-container {
    padding-top: 90px;
    text-align: center;
    min-height: 800px;
    position: relative;

    .top-text {
      width: 600px;

      @include respond(small-mobile) {
        width: 100%;
      }

    }

    h1 {
      color: #FFFFFF;
      font-size: 80px;
      font-weight: 900;
      line-height: 0.9em;
      text-shadow: 0 0 23px rgb(1 11 57 / 30%);
      @include respond(small-mobile) {
        font-size: 30px;
      }
    }

    p {
      line-height: 1.5em;
      font-size: 20px;
      font-weight: 600;
      color: #0A0F13;
      text-shadow: 0 0 2px #ffffff;
      @include respond(small-mobile) {
        font-size: 18px;
      }
    }
  }
}

.home-items-section {
  position: relative;
  margin-top: -280px;

  .home-item {
    width: 220px;
    margin: 0 10px 20px 10px;
    padding: 20px 10px 30px 10px;
    background-color: transparent;
    background-image: linear-gradient(180deg, #FFFFFF 0%, #F8F8F8 100%);
    border-radius: 3px;
    box-shadow: 0 0 23px 0 rgb(46 131 164 / 50%);

    @include respond(extra-small-mobile) {
      width: 95%;
    }
    @include respond(small-mobile) {
      width: 95%;
    }
    @include respond(large-mobile) {
      width: 95%;
    }
  }

  .item-icon {
    width: 70px;
    height: 70px;
    line-height: 1;
    transition: all .3s;
    text-align: center;
    fill: $secondary;
    color: $secondary;
    border: 3px solid $secondary;
    border-radius: 50%;
    background-color: transparent;

    img {
      width: 65px;
      height: 65px;
      padding: 10px;
    }
  }


  h3 {
    color: $primary;
    font-size: 20px;
    font-weight: 600;
  }

  p {
    color: $black--two;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    line-height: 1.3;
  }
}

.home-content-section {

  h3 {
    font-size: 38px;
    font-weight: 400;
    color: $primary;
  }

  h5 {
    text-align: center;
    margin: 0 auto;
    font-size: 14px;
    font-weight: 500;
    color: $black--two;
  }

  p {
    color: $black--two;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
  }

  .home-why-item {
    p {
      font-size: 15px;
      line-height: 24px;
      color: $grey--seven;
    }

    li {
      font-size: 15px;
      line-height: 24px;
      font-weight: 400;
      color: $grey--seven;
    }

    .list-icon {
      color: $grey--seven;
      font-size: 18px;
      stroke-width: 2;
    }

    .why-image-1 {
      width: 500px;
      height: 334px;

      @include respond(large-mobile) {
        width: 300px;
        height: 200px;
      }

    }

    .why-image-2 {
      position: relative;
      margin-top: -50px;
      margin-right: -50px;
      width: 300px;
      height: 187px;
    }
  }
}
