// fonts
$work-sans: "Work Sans", sans-serif;
//$work-sans: "Open Sans", Sans-serif, serif;
$spectral: "Spectral", serif;

// color
$black: #000;
$black--two: #222222;
$black--three: #222;
$black--four: #333;
$white: #fff;
$grey: #7e7e7e;
$grey--two: #d6d6d6;
$grey--three: #e7e7e7;
$grey--four: #f8f8f8;
$grey--five: #d1d1d1;
$grey--six: #999999;
$grey--seven: #777777;
$grey--eight: #d0d0d0;
$grey--nine: #dcdcdc;
$grey--ten: #eeeeee;
$grey--eleven: #ccc;
$grey--twelve: #aaaaaa;
$grey--thirteen: #dddddd;
$grey--fourteen: #696969;
$grey--fifteen: #ababab;
$grey--sixteen: #d8d8d8;
$grey--seventeen: #e0e0e0;
$grey--eighteen: #ededed;
$grey--nineteen: #e8e8e8;
$grey--twenty: #e6e6e6;
$grey--twentyOne: #f7f7f7;
$grey--twentyTwo: #f2f2f2;
$grey--twentyThree: #505050;
$grey--twentyFour: #f9f9f9;
$grey--twentyFive: #f0ddd6;
$grey--twentySix: #878787;
$grey--twentySeven: #9b9b9b;
$grey--twentyEight: #ebebeb;
$grey--twentyNine: #f4f4f4;
$grey--thirty: #abb8c0;
$red--one: #d3122a;
$red--two: #ff0000;
$red--three: #c61932;
$red--four: #d11830;
$green--one: #98d8ca;
$green--two: #edf6eb;
$green--three: #a3d39c;
$green--four: #e6f0f4;
$blue--one: #1e73be;
$blue--two: #ecf4fb;
$blue--three: #7eb7e6;
$blue--four: #6d7f96;
$blue--five: #404969;
$yellow--one: #dd9933;
$yellow--two: #f5cc26;
$yellow--three: #f8f5de;
$yellow--four: #ddb759;
$orange--one: #f26522;
$theme-color--default: $black;
$theme-color--heading: $black;
$transition--default: all 0.3s;
