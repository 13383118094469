/*=============================================
=            utilities            =
=============================================*/


/* custom spacing */

.space-mt {
  &--10 {
    margin-top: 10px;
  }

  &--20 {
    margin-top: 20px;
  }

  &--25 {
    margin-top: 25px;
  }

  &--30 {
    margin-top: 30px;
  }

  &--40 {
    margin-top: 40px;
  }

  &--50 {
    margin-top: 50px;
  }

  &--r60 {
    margin-top: 60px;

    @include respond(extra-large-mobile) {
      margin-top: 50px;
    }

    @include respond(large-mobile) {
      margin-top: 30px;
    }
  }


  &--r100 {
    margin-top: 100px;

    @include respond(extra-large-mobile) {
      margin-top: 80px;
    }

    @include respond(large-mobile) {
      margin-top: 60px;
    }
  }

  &--r130 {
    margin-top: 130px;

    @include respond(extra-large-mobile) {
      margin-top: 80px;
    }

    @include respond(large-mobile) {
      margin-top: 60px;
    }
  }

  &-mobile-only {
    &--30 {
      @include respond(extra-large-mobile) {
        margin-top: 30px;
      }

      @include respond(large-mobile) {
        margin-top: 30px;
      }
    }

    &--50 {
      @include respond(extra-large-mobile) {
        margin-top: 50px;
      }

      @include respond(large-mobile) {
        margin-top: 50px;
      }
    }
  }
}

.space-mb {
  &--5 {
    margin-bottom: 5px;
  }

  &--10 {
    margin-bottom: 10px !important;
  }

  &--n10 {
    margin-bottom: -10px !important;
  }

  &--15 {
    margin-bottom: 15px;
  }

  &--20 {
    margin-bottom: 20px;
  }

  &--25 {
    margin-bottom: 25px;
  }

  &--30 {
    margin-bottom: 30px;
  }

  &--35 {
    margin-bottom: 35px;
  }

  &--40 {
    margin-bottom: 40px;
  }

  &--50 {
    margin-bottom: 50px;
  }

  &--r50 {
    margin-bottom: 50px;

    @include respond(large-mobile) {
      margin-bottom: 30px;
    }
  }

  &--m20 {
    margin-bottom: -20px;
  }

  &--m30 {
    margin-bottom: -30px;

    @include respond(large-mobile) {
      margin-bottom: -30px;
    }
  }

  &--rm50 {
    margin-bottom: -50px;

    @include respond(large-mobile) {
      margin-bottom: -30px;
    }
  }


  &--60 {
    margin-bottom: 60px;
  }

  &--r60 {
    margin-bottom: 60px;

    @include respond(extra-large-mobile) {
      margin-bottom: 60px;
    }

    @include respond(large-mobile) {
      margin-bottom: 40px;
    }
  }

  &--r100 {
    margin-bottom: 100px;

    @include respond(extra-large-mobile) {
      margin-bottom: 80px;
    }

    @include respond(large-mobile) {
      margin-bottom: 60px;
    }
  }

  &--r80 {
    margin-bottom: 80px;

    @include respond(extra-large-mobile) {
      margin-bottom: 60px;
    }

    @include respond(large-mobile) {
      margin-bottom: 40px;
    }
  }

  &--r130 {
    margin-bottom: 130px !important;

    @include respond(extra-large-mobile) {
      margin-bottom: 80px !important;
    }

    @include respond(large-mobile) {
      margin-bottom: 60px !important;
    }
  }

  &-mobile-only {
    &--30 {
      @include respond(extra-large-mobile) {
        margin-bottom: 30px;
      }

      @include respond(large-mobile) {
        margin-bottom: 30px;
      }
    }

    &--40 {
      @include respond(extra-large-mobile) {
        margin-bottom: 40px;
      }

      @include respond(large-mobile) {
        margin-bottom: 40px;
      }
    }

    &--m30 {
      @include respond(extra-large-mobile) {
        margin-bottom: -30px;
      }

      @include respond(large-mobile) {
        margin-bottom: -30px;
      }
    }

    &--50 {
      @include respond(extra-large-mobile) {
        margin-bottom: 50px;
      }

      @include respond(large-mobile) {
        margin-bottom: 50px;
      }
    }

    &--m50 {
      @include respond(extra-large-mobile) {
        margin-bottom: -50px;
      }

      @include respond(large-mobile) {
        margin-bottom: -50px;
      }
    }
  }
}

.space-mr {
  &--10 {
    margin-right: 10px;
  }

  &--20 {
    margin-right: 20px;
  }
}

.space-pt {
  &--10 {
    padding-top: 10px;
  }

  &--15 {
    padding-top: 15px;
  }

  &--30 {
    padding-top: 30px;
  }

  &--40 {
    padding-top: 40px;
  }

  &--50 {
    padding-top: 50px;
  }

  &--70 {
    padding-top: 70px;

    @include respond(extra-large-mobile) {
      padding-top: 50px;
    }

    @include respond(large-mobile) {
      padding-top: 40px;
    }

  }

  &--100 {
    padding-top: 100px;

    @include respond(extra-large-mobile) {
      padding-top: 50px;
    }

    @include respond(large-mobile) {
      padding-top: 40px;
    }
  }

  &--r100 {
    padding-top: 100px;

    @include respond(extra-large-mobile) {
      padding-top: 80px;
    }

    @include respond(large-mobile) {
      padding-top: 60px;
    }
  }

  &--r130 {
    padding-top: 130px;

    @include respond(extra-large-mobile) {
      padding-top: 80px;
    }

    @include respond(large-mobile) {
      padding-top: 60px;
    }
  }
}

.space-pr {
  &--15 {
    padding-right: 15px;
  }
}

.space-pb {
  &--10 {
    padding-bottom: 10px;
  }

  &--15 {
    padding-bottom: 15px;
  }

  &--30 {
    padding-bottom: 30px;
  }

  &--50 {
    padding-bottom: 50px;

    @include respond(extra-large-mobile) {
      padding-bottom: 30px;
    }

    @include respond(large-mobile) {
      padding-bottom: 15px;
    }

  }

  &--70 {
    padding-bottom: 70px;

    @include respond(extra-large-mobile) {
      padding-bottom: 50px;
    }

    @include respond(large-mobile) {
      padding-bottom: 40px;
    }

  }

  &--100 {
    padding-bottom: 100px;
  }

  &--r100 {
    padding-bottom: 100px;

    @include respond(extra-large-mobile) {
      padding-bottom: 80px;
    }

    @include respond(large-mobile) {
      padding-bottom: 60px;
    }
  }

  &--r130 {
    padding-bottom: 130px;

    @include respond(extra-large-mobile) {
      padding-bottom: 80px;
    }

    @include respond(large-mobile) {
      padding-bottom: 60px;
    }
  }
}

.space-pl {
  &--15 {
    padding-left: 15px;
  }

  &--30 {
    padding-left: 30px;
  }

  &--100 {
    padding-left: 100px;

    @include respond(extra-large-mobile) {
      padding-left: 80px;
    }

    @include respond(large-mobile) {
      padding-left: 70px;
    }

  }
}

.space-py {
  &--9 {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
  }

  &-mobile-only {
    &--15 {
      @include respond(extra-large-mobile) {
        padding-top: 15px;
        padding-bottom: 15px;
      }

      @include respond(large-mobile) {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }

    &--30 {
      @include respond(extra-large-mobile) {
        padding-top: 30px;
        padding-bottom: 30px;
      }

      @include respond(large-mobile) {
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }
  }
}

/* background color */

.bg-color {
  &--white {
    background-color: $white;
  }

  &--red {
    background-color: $red;
  }

  &--grey {
    background-color: $grey--four;
  }

  &--grey-two {
    background-color: $grey--twentyNine;
  }

  &--grey-three {
    background-color: $grey--twentyOne;
  }

  &--blue {
    background-color: $blue--four;
  }

  &--blue-two {
    background-color: $blue--five;
  }

  &--red {
    background-color: $red--four;
  }

  &--green {
    background-color: $green--four;
  }

  &--orange {
    background-color: $orange--one;
  }
}

/* background image */
.bg-img {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

/* borders */
.border-top--grey {
  border-top: 1px solid $grey--eighteen;
}

.border-bottom--grey {
  border-bottom: 1px solid $grey--eighteen;
}

/* display */

.hide-down--small-desktop {
  @include respond(small-desktop) {
    display: none !important;
  }

  @include respond(extra-large-mobile) {
    display: none !important;;
  }

  @include respond(large-mobile) {
    display: none !important;;
  }

  @include respond(small-mobile) {
    display: none !important;;
  }

  @include respond(extra-small-mobil) {
    display: none !important;;
  }
}

.hide-down--large-desktop {
  @include respond(large-desktop) {
    display: none !important;
  }

  @include respond(small-desktop) {
    display: none !important;
  }

  @include respond(extra-large-mobile) {
    display: none !important;;
  }

  @include respond(large-mobile) {
    display: none !important;;
  }

  @include respond(small-mobile) {
    display: none !important;;
  }

  @include respond(extra-small-mobil) {
    display: none !important;;
  }
}

.hide-down--extra-large-mobile {

  @include respond(extra-large-mobile) {
    display: none !important;;
  }

  @include respond(large-mobile) {
    display: none !important;;
  }

  @include respond(small-mobile) {
    display: none !important;;
  }

  @include respond(extra-small-mobil) {
    display: none !important;;
  }
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500;
}

.text-black--two {
  color: $black--two !important;
}

.text-black--four {
  color: $black--four !important;
}

.text-white {
  color: $white;
}

.min-h-r70 {
  min-height: 70px;

  @include respond(large-mobile) {
    min-height: 30px;
  }

  @include respond(small-mobile) {
    min-height: 20px;
  }

  @include respond(extra-small-mobil) {
    min-height: 15px;
  }

}

.max-w-200 {
  max-width: 200px !important;
}

.max-w-800 {
  max-width: 800px !important;
}

.min-h-130 {
  min-height: 130px;
}

.min-h-500 {
  min-height: 500px;
}

.min-w-80 {
  min-width: 80px;
}

.min-w-100 {
  min-width: 100px;
}

.min-w-150 {
  min-width: 150px;
}

.min-w-r170 {
  min-width: 170px;

  @include respond(large-mobile) {
    min-width: 100px;
  }

  @include respond(small-mobile) {
    min-width: 80px;
  }

  @include respond(extra-small-mobil) {
    min-width: 70px;
  }

}

.w-r350 {
  width: 350px;

  @include respond(large-mobile) {
    width: 250px;
  }

  @include respond(small-mobile) {
    width: 250px;
  }

  @include respond(extra-small-mobil) {
    width: 250px;
  }
}

.w-150 {
  width: 150px;

}

/* text color */
.text {
  &--grey-six {
    color: $grey--six;
  }

  &--grey-seven {
    color: $grey--seven;
  }
}


/*=====  End of utilities  ======*/
